<template>
  <div>
    <p class="text-h6 py-6">{{ $t("accountDetails.personalData") }}</p>
    <v-row>
      <v-col class="pr-0" cols="4"
        ><p class="text-sm-body-2">
          {{ $t("accountDetails.companyName") }}
        </p></v-col
      >
      <v-col class="px-0"
        ><p class="text-h5">{{ $keycloak.company }}</p></v-col
      >
    </v-row>
    <v-row>
      <v-col class="pr-0" cols="4"
        ><p class="text-sm-body-2">
          {{ $t("accountDetails.firstName") }}
        </p></v-col
      >
      <v-col class="px-0"
        ><p class="text-h5">
          {{ $keycloak.tokenParsed.given_name }}
        </p></v-col
      >
    </v-row>
    <v-row>
      <v-col class="pr-0" cols="4"
        ><p class="text-sm-body-2">
          {{ $t("accountDetails.lastName") }}
        </p></v-col
      >
      <v-col class="px-0"
        ><p class="text-h5">{{ $keycloak.tokenParsed.family_name }}</p></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="4"
        ><p class="text-sm-body-2">
          {{ $t("accountDetails.email") }}
        </p></v-col
      >
      <v-col class="px-0"
        ><p class="text-h5">{{ $keycloak.userName }}</p></v-col
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "personalData"
};
</script>

<style scoped></style>
