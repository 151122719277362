<template>
  <v-card
    style="background-color: #FAFAFA;"
    class="pa-8 py-6 pb-16"
    elevation="2"
  >
    <p class="text-h6 pb-2" style="font-weight: normal">
      {{ selectedAccount.name }} - {{ $t("accountDetails.credits") }}
    </p>
    <v-row>
      <v-col>
        <p class="font-weight-medium pb-0">
          {{ $t("accountDetails.currentCredit") }}
        </p>
        <p class="text-h5" style="color: #34948F">
          {{ selectedAccount.balance }} credits
        </p>
      </v-col>
    </v-row>
    <v-row class="ml-1 mt-5">
      <p class="text-sm-body-2">
        {{ $t("accountDetails.creditText") }}
      </p></v-row
    >
    <v-divider class="my-8" />
    <v-row>
      <v-col>
        <p class="font-weight-bold pb-4">
          {{ $t("accountDetails.lastTransactions") }}
        </p>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-select
          clearable
          v-model="filteredRegion"
          :items="regions"
          :label="$t('accountDetails.region')"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      style="background-color: #FAFAFA; justify-content: left"
      dense
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsperPage"
      height="200px"
      :headers="headers"
      :search="filteredRegion"
      :items="transactionsList.items"
      item-key="name"
    ></v-data-table>
    <v-row>
      <span class="text-body-2 font-weight-light">
        {{ page }} of {{ pageCount }}</span
      >
      <v-spacer />
      <v-btn
        :disabled="!(page > 1)"
        @click="page = page - 1"
        x-small
        text
        class="text-lowercase text-decoration-underline"
      >
        {{ $t("accountDetails.prev") }}
      </v-btn>
      <v-btn
        :disabled="!(pageCount > page)"
        @click="page = page + 1"
        x-small
        text
        class="text-lowercase text-decoration-underline"
      >
        {{ $t("accountDetails.next") }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import creditMixin from "@/core/mixins/credit.mixin";

export default {
  name: "accountInfo",
  props: {
    selectedAccount: {
      type: Object
    },
    orders: {
      type: Array
    }
  },
  data: () => ({
    transactionsLoading: true,
    transactionsList: [],
    filteredRegion: "",
    itemsperPage: 5,
    regions: [],
    page: 1,
    pageCount: 0,
    headers: [
      { text: "Date", width: "30%", value: "date" },
      { text: "Credits", width: "20%", value: "amount" },
      { text: "Region", value: "order_name" }
    ]
  }),
  mixins: [creditMixin],
  methods: {
    async loadTransactions() {
      this.transactionsLoading = true;
      let response = await this.fetchAccountTransactions(
        this.selectedAccount.id,
        "eoapp"
      );
      response.items.forEach(transaction => {
        transaction.date = transaction.created_at.split("T")[0];
      });
      this.transactionsLoading = false;
      return response;
    },
    async defineRegionNames() {
      let regionsSet = new Set();
      this.transactionsList.items.forEach(transaction => {
        let filter = this.orders.filter(
          order => transaction.order_id == order.id
        );

        if (filter.length > 0) {
          regionsSet.add(filter[0].order_name);
          transaction.order_name = filter[0].order_name;
        } else {
          transaction.order_name = "";
        }
      });
      this.regions = Array.from(regionsSet);
    }
  },
  watch: {
    async selectedAccount() {
      this.transactionsList = await this.loadTransactions();
      this.defineRegionNames();
    }
  },
  async created() {
    this.transactionsList = await this.loadTransactions();
    this.defineRegionNames();
  }
};
</script>

<style scoped>
.card.fill-height {
  height: 70vh !important;
  //width: 95vw !important;
}
</style>
