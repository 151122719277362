<template>
  <v-main class="fill-height">
    <div class="overflow-x-hidden overflow-y-auto ">
      <v-row>
        <v-col>
          <v-btn
            class="pl-0"
            text
            @click="$router.go(-1)"
            style="color: #173466"
          >
            <v-icon size="x-large">mdi-chevron-left</v-icon>
            <span class="text-body-1"> {{ $t("thresholdSettings.back") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="full-height ">
        <v-layout>
          <v-flex>
            <v-row class="ma-2">
              <v-col>
                <v-row class="mb-10 display-1"
                  >{{ $t("accountDetails.accountDetails") }}
                </v-row>
                <v-row class="mx-1" justify="space-between">
                  <v-col>
                    <personal-data></personal-data>
                    <v-divider />
                    <p class="text-h6 pt-5">
                      {{ $t("accountDetails.creditAccounts") }}
                    </p>
                    <v-card
                      style="background-color: #FAFAFA; width: 100%"
                      elevation="0"
                      class="my-4 text-h6"
                      v-for="account in creditAccounts"
                      :key="account.id"
                      :outlined="account === selectedAccount"
                      @click="selectedAccount = account"
                    >
                      <v-card-title class="py-2">
                        {{ account.name }}
                        <v-spacer />
                        <v-icon large>mdi-chevron-right</v-icon>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col>
                    <account-info
                      v-if="!accountsLoading"
                      :selected-account="selectedAccount"
                      :orders="orders"
                    ></account-info>
                    <v-overlay v-else opacity="0.2" absolute>
                      <div>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          size="64"
                        ></v-progress-circular>
                      </div>
                    </v-overlay>
                    <v-row class="mt-8 mr-1 mb-1">
                      <v-spacer />
                      <contact-popup
                        :user-info="userInfo"
                        :type="'textButton'"
                      ></contact-popup>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-main>
</template>

<script>
import creditMixin from "@/core/mixins/credit.mixin";
import PersonalData from "@/core/components/accountDetails/personalData.vue";
import AccountInfo from "@/core/components/accountDetails/accountInfo.vue";
import axios from "axios";
import router from "@/core/router";
import ContactPopup from "@/core/components/bar/contactPopup.vue";

export default {
  name: "AccountDetails",
  components: { ContactPopup, AccountInfo, PersonalData },
  data: () => ({
    creditAccounts: [],
    accountsLoading: true,
    selectedAccount: {},
    orders: []
  }),
  mixins: [creditMixin],
  computed: {
    userInfo() {
      return [this.$keycloak.userName, this.$keycloak.fullName];
    }
  },
  methods: {
    router() {
      return router;
    },
    async fetchOrders() {
      const response = await axios.get(`/orders`);
      return response.data;
    }
  },
  async created() {
    this.creditAccounts = await this.fetchUserAccounts();
    this.selectedAccount = this.creditAccounts[0];
    this.orders = await this.fetchOrders();
    this.accountsLoading = false;
  }
};
</script>

<style scoped>
.full-height {
  height: 87vh !important;
  width: 90vw !important;
}
</style>
